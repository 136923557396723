import { LocalizationKeys } from '../../i18n';
import { CustomerType } from '../../types/organization';
import { Severity } from './helpers';

export const messages: Record<
  CustomerType,
  Record<Severity, LocalizationKeys>
> = {
  'End Customer': {
    low: 'components.announcements.overdue.common.low',
    medium: 'components.announcements.overdue.common.medium',
  },
  BPO: {
    low: 'components.announcements.overdue.common.low',
    medium: 'components.announcements.overdue.common.medium',
  },
  'Software vendor (=OEM)': {
    low: 'components.announcements.overdue.common.low',
    medium: 'components.announcements.overdue.oem.medium',
  },
};
