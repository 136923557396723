import { getIDFromUrl, Url } from '@rossum/api-client';
import {
  Annotation,
  DatapointData,
  MultivalueData,
  SectionData,
  TupleData,
} from '@rossum/api-client/annotations';
import { Document } from '@rossum/api-client/documents';
import {
  AutomationBlocker,
  EvaluateFormulasResponse,
} from '@rossum/api-client/internal';
import { SchemaAction } from '@rossum/api-client/schemas';
import { Message } from '@rossum/api-client/shared';
import { GridColDef } from '@rossum/ui/x-data-grid-pro';
import { UseQueryResult } from '@tanstack/react-query';
import { compact, Dictionary, fromPairs, groupBy, pick, range } from 'lodash';
import { useMemo } from 'react';
import { useCalculatePreview } from './useCalculatePreview';
import { PAGE_SIZE } from './useGetAnnotationsForPreview';

const findBySchemaId = <
  Base extends { schemaId: string | null },
  C extends (Base & { children: C[] }) | Base,
>(
  content: Array<C>,
  schemaId: string
): Array<C> =>
  content.reduce<Array<C>>(
    (results, dp) =>
      dp.schemaId === schemaId
        ? [...results, dp]
        : 'children' in dp
          ? [...results, ...findBySchemaId(dp.children, schemaId)]
          : results,
    []
  );

const getMaxLength = (arr: Array<Array<unknown>>) =>
  // at least one line per annotation should be displayed
  Math.max(1, ...arr.map(a => a.length));

// n + 1 for "...X more"
const MAX_ROWS_COUNT = 4;

export const globals = 'globals';

export const documentsColumn = 'document';

export const columnsPrefix = 'columns';

export type DatapointColumn = `${typeof columnsPrefix}.${string}`;

type DatapointValue = SectionData | DatapointData | TupleData | MultivalueData;

export type RowModel = {
  id: number | string;
  annotationUrl: Url;
  [documentsColumn]: string | undefined;
  [globals]: {
    messages: Message[] | undefined;
    automationBlockers: AutomationBlocker[] | undefined;
    actions: SchemaAction[] | undefined;
  };
  isFetching: boolean;
} & Record<
  DatapointColumn,
  | {
      datapoints: Array<DatapointValue>;
      messages: Dictionary<Message[]>;
      automationBlockers: Dictionary<AutomationBlocker[]>;
    }
  | undefined
>;

type UseRowsParams = {
  annotations: Annotation[] | undefined;
  documents: Document[] | undefined;
  columns: Array<GridColDef<RowModel>>;
  evaluationQueries: ReturnType<typeof useCalculatePreview>;
};

type TransformDataToRowsParams = {
  columns: Array<GridColDef<RowModel>>;
  annotations: Annotation[] | undefined;
  documentsMap: Dictionary<string>;
  evaluationQueriesByAnnotationUrl: Record<
    Url,
    UseQueryResult<EvaluateFormulasResponse>
  >;
};

export const transformDataToRows = ({
  columns,
  annotations,
  documentsMap,
  evaluationQueriesByAnnotationUrl,
}: TransformDataToRowsParams): Array<RowModel> | undefined => {
  return annotations?.flatMap<RowModel>(({ url: annotationUrl, document }) => {
    const annotationEvaluationQuery =
      evaluationQueriesByAnnotationUrl[annotationUrl];

    const messagesMap = groupBy(
      annotationEvaluationQuery?.data?.messages,
      'id'
    );

    const automationBlockersMap = groupBy(
      annotationEvaluationQuery?.data?.automationBlockers,
      'id'
    );

    const datapointColumns = columns.filter(c =>
      c.field.startsWith(`${columnsPrefix}.`)
    );

    const datapointsData = datapointColumns.flatMap(({ field }) =>
      annotationEvaluationQuery?.data?.annotationContent
        ? [
            findBySchemaId(
              annotationEvaluationQuery?.data?.annotationContent,
              field.replace(`${columnsPrefix}.`, '')
            ),
          ]
        : []
    );

    return range(Math.min(getMaxLength(datapointsData), MAX_ROWS_COUNT)).map(
      index => {
        const indexBasedAttributes =
          index === 0
            ? {
                [documentsColumn]: documentsMap[document],
                [globals]: {
                  messages: messagesMap.all,
                  automationBlockers: automationBlockersMap.all,
                  actions: annotationEvaluationQuery?.data?.actions ?? [],
                },
              }
            : {
                [documentsColumn]: '',
                [globals]: {
                  messages: [],
                  automationBlockers: [],
                  actions: [],
                },
              };
        return {
          id: `${getIDFromUrl(annotationUrl)}-${index}`,
          annotationUrl,
          ...indexBasedAttributes,
          isFetching: annotationEvaluationQuery?.isFetching ?? false,
          ...datapointColumns.reduce((acc, column, columnIndex) => {
            const datapoints = datapointsData.length
              ? compact(
                  index === MAX_ROWS_COUNT - 1
                    ? datapointsData[columnIndex]?.slice(MAX_ROWS_COUNT - 1)
                    : [datapointsData[columnIndex]?.[index]]
                )
              : undefined;

            const datapointsIds = datapoints?.map(({ id }) => String(id)) ?? [];

            return {
              ...acc,
              [column.field]: datapoints
                ? {
                    datapoints,
                    messages: pick(messagesMap, datapointsIds),
                    automationBlockers: pick(
                      automationBlockersMap,
                      datapointsIds
                    ),
                  }
                : undefined,
            };
          }, {}),
        };
      }
    );
  });
};

export const useRows = ({
  columns,
  annotations,
  documents,
  evaluationQueries,
}: UseRowsParams): Array<RowModel> => {
  const documentsMap: Dictionary<string> = useMemo(
    () =>
      fromPairs(
        documents?.map(({ url, originalFileName }) => [
          url,
          originalFileName ?? '',
        ]) ?? []
      ),
    [documents]
  );

  const evaluationQueriesByAnnotationUrl: Record<
    Url,
    UseQueryResult<EvaluateFormulasResponse>
  > = Object.assign(
    {},
    ...evaluationQueries.flatMap(query =>
      query.data ? [{ [query.data.annotationUrl]: query }] : []
    )
  );

  const rows =
    transformDataToRows({
      columns,
      annotations,
      documentsMap,
      evaluationQueriesByAnnotationUrl,
    }) ??
    range(PAGE_SIZE).map(i => ({
      id: `fake-row-${i}`,
      annotationUrl: `${i}`,
      [documentsColumn]: undefined,
      [globals]: {
        actions: undefined,
        messages: undefined,
        automationBlockers: undefined,
      },
      isFetching: true,
    }));

  return rows;
};
