import { getIDFromUrl } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import {
  Redirect,
  Route,
  RouteChildrenProps,
  Switch,
  useRouteMatch,
} from 'react-router';
import { useSchema } from '../../../business/schema/useSchema';
import { RulesPage } from '../rules';
import { RulePage, RulePageDetail } from '../rules/pages/RulePage';

type RulesRouteProps = RouteChildrenProps & {
  currentQueue: Queue;
  parentPath: string;
};

export const RulesRoute = ({
  location,
  currentQueue,
  parentPath,
}: RulesRouteProps) => {
  const { data } = useSchema({
    schemaId: getIDFromUrl(currentQueue.schema),
  });

  const match = useRouteMatch();

  return (
    <Switch location={location}>
      <Route path={`${match.path}`} exact>
        {data ? (
          <RulesPage
            schema={data}
            queue={currentQueue}
            parentPath={parentPath}
          />
        ) : null}
      </Route>
      <Route path={`${match.path}/:ruleId?/detail`} exact>
        {data
          ? routeChildrenProps => {
              const ruleId = Number(routeChildrenProps.match?.params.ruleId);

              return (
                <RulePageDetail
                  // Key is important for re-mount when navigating to newly created rule
                  key={ruleId}
                  queue={currentQueue}
                  schema={data}
                  ruleId={ruleId}
                  parentPath={parentPath}
                />
              );
            }
          : null}
      </Route>
      <Route path={`${match.path}/new`} exact>
        {data ? (
          <RulePage
            schema={data}
            queue={currentQueue}
            parentPath={parentPath}
          />
        ) : null}
      </Route>
      <Route path={`${match.path}`}>
        <Redirect
          to={{
            pathname: `${match.url}`,
            state: location.state,
          }}
        />
      </Route>
    </Switch>
  );
};
