import { includes } from 'lodash';

const toAbsoluteUrl = (url: string) =>
  url.indexOf('http') === 0 ? url : new URL(url, window.location.origin).href;

// helpers
export const boolify = (maybeString: string | unknown) =>
  typeof maybeString === 'string'
    ? includes(maybeString, 'true')
    : !!maybeString;

// environment variables
const reactAppApiUrl = window && window.env && window.env.REACT_APP_API_URL;
const reactAppCodeVersion =
  window && window.env && window.env.REACT_APP_CODE_VERSION;
const reactAppEmbedded = () =>
  window && window.env && window.env.REACT_APP_EMBEDDED;
const reactAppEnableRollbar =
  window && window.env && window.env.REACT_APP_ENABLE_ROLLBAR;
export const reactAppLocale =
  window && window.env && window.env.REACT_APP_LOCALE;
const reactAppRollbarEnvironment =
  window && window.env && window.env.REACT_APP_ROLLBAR_ENVIRONMENT;
export const reactAppUrl = window && window.env && window.env.REACT_APP_URL;
const reactAppDocsUrl = window && window.env && window.env.REACT_APP_DOCS_URL;
const reactAppDataMatchingUrl =
  window && window.env && window.env.REACT_APP_DATA_MATCHING_URL;
const beamerProductIdEnv =
  window && window.env && window.env.REACT_APP_BEAMER_PRODUCT_ID;
const unleashClientKeyEnv =
  window && window.env && window.env.REACT_APP_UNLEASH_CLIENT_KEY;
const reactAppAuthInfoUrl =
  window && window.env && window.env.REACT_APP_AUTH_INFO_URL;
const reactCustomerDomainEnabled =
  window && window.env && window.env.REACT_APP_CUSTOMER_DOMAIN;
const muiDataGridLicenceKeyEnv =
  window && window.env && window.env.MUI_DATA_GRID_LICENCE_KEY;
const prometheusUsernameEnvvar =
  window && window.env && window.env.REACT_APP_PROMETHEUS_USERNAME;
const prometheusPasskeyEnvvar =
  window && window.env && window.env.REACT_APP_PROMETHEUS_PASSKEY;

// strings
export const docsUrl = toAbsoluteUrl(
  reactAppDocsUrl ||
    process.env.REACT_APP_DOCS_URL ||
    'https://elis.rossum.ai/api/docs'
);

export const appUrl = toAbsoluteUrl(
  reactAppUrl || process.env.REACT_APP_URL || 'http://localhost:3000'
);

export const customApiUrl = window.localStorage.getItem('customApiUrlValue');

export const apiUrl =
  customApiUrl || 'https://7587-39.app.review.r8.lol/api/v1' || reactAppApiUrl;

export const absoluteApiUrl = toAbsoluteUrl(apiUrl);

export const emailDomainName = new URL(absoluteApiUrl).hostname;

export const apiUrlWithoutVersion = absoluteApiUrl.replace('/v1', '');

export const appAuthInfoUrl =
  reactAppAuthInfoUrl || process.env.REACT_APP_AUTH_INFO_URL || '';

export const customerDomainEnabled =
  reactCustomerDomainEnabled ||
  process.env.REACT_APP_CUSTOMER_DOMAIN === 'true';

export const beamerProductId =
  beamerProductIdEnv ||
  process.env.REACT_APP_BEAMER_PRODUCT_ID ||
  'MeHmBgBA43079';

export const beamerSelector = 'rossumBeamerSelector';

export const muiDataGridLicenseKey =
  muiDataGridLicenceKeyEnv ||
  process.env.MUI_DATA_GRID_LICENCE_KEY ||
  'ef84daf23a0c5c088ea37f782da398f2Tz05MzU1MixFPTE3NTE1MzYwNTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

export const GTM_ID = 'GTM-ML6R7M5';

export const unleashClientKey =
  unleashClientKeyEnv || process.env.REACT_APP_UNLEASH_CLIENT_KEY || '';

export const prometheusUsername =
  prometheusUsernameEnvvar ||
  process.env.REACT_APP_PROMETHEUS_USERNAME ||
  'frontend';

export const prometheusPasskey =
  prometheusPasskeyEnvvar ||
  process.env.REACT_APP_PROMETHEUS_PASSKEY ||
  'IrjomEfwavTapAuvReb2';

// booleans
export const shouldRollbar = boolify(
  reactAppEnableRollbar || process.env.REACT_APP_ENABLE_ROLLBAR
);

export const currentCodeVersion =
  reactAppCodeVersion ||
  document.querySelector<HTMLMetaElement>('meta[name="GIT_COMMIT"]')?.content ||
  '';

export const rollbarEnvironment =
  reactAppRollbarEnvironment || process.env.REACT_APP_ROLLBAR_ENVIRONMENT;

export const isPathBasedEmbeddedMode = () =>
  window.location.pathname.startsWith('/embedded/');

// Legacy way (embedded.elis.rossum.ai)
const isDomainBasedEmbeddedMode = () =>
  boolify(reactAppEmbedded() || process.env.REACT_APP_EMBEDDED);

export const isEmbedded = () =>
  isDomainBasedEmbeddedMode() || isPathBasedEmbeddedMode();

export const isDevelop = boolify(process.env.REACT_APP_DEVELOP_VERSION);

export const DEV_FEATURES_ENABLED =
  window.localStorage.getItem('devFeaturesEnabled') || false;

export const DEV_DEBUG_ENABLED =
  window.localStorage.getItem('devDebugEnabled') || false;

export const REACT_QUERY_DEVTOOLS_ENABLED =
  !!window.localStorage.getItem('reactQueryDevtoolsEnabled') || false;

const dataMatchingUrlValue =
  reactAppDataMatchingUrl || process.env.REACT_APP_DATA_MATCHING_URL;

export const dataMatchingUrl = dataMatchingUrlValue
  ? toAbsoluteUrl(dataMatchingUrlValue)
  : '';

export const currentTagVersion =
  document.querySelector<HTMLMetaElement>('meta[name="GIT_TAG"]')?.content ||
  '';
