// Generated by ts-to-zod
import { z } from 'zod';
import { schemaRuleSchema } from '../../../rules/models/schemaRule.schema';
import { schemaSectionSchema } from '../../../schemas/models/schemaSection.schema';

export const suggestTriggerPayloadSchema = z.object({
  userQuery: z.string(),
  schemaContent: z.array(schemaSectionSchema),
  schemaRule: schemaRuleSchema.partial(),
});

export const suggestTriggerResultSchema = z.object({
  triggerCondition: z.string().optional(),
  triggerConditionSummary: z.string().optional(),
});

export const suggestTriggerResponseSchema = z.object({
  results: z.array(suggestTriggerResultSchema),
});
