import { Url } from '@rossum/api-client';
import { SchemaSection } from '@rossum/api-client/schemas';
import { Code, CodeOff } from '@rossum/ui/icons';
import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { ControllerFieldState, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ContentWrapper } from '../../../ui/content-group/ContentWrapper';
import { SplitContent } from '../../../ui/content-group/SplitContent';
import { AuroraSuggest } from '../../../ui/icons/AuroraSuggest';
import FormulaEditor from '../FormulaEditor/FormulaEditor';
import { getValidSchemaFields } from '../FormulaEditor/helpers';
import { FormulaPreviewGrid } from '../FormulaPreview/FormulaPreviewGrid';
import Suggester from '../FormulaSuggester/Suggester';
import TemplateSuggesterButton from '../FormulaSuggester/TemplateSuggesterButton';
import { FormulaCopilot } from './components/FormulaCopilot';
import { SummaryBox } from './components/SummaryBox';
import { updateFieldFormula } from './helpers/updateFieldFormula';
import { useFormulaSummary } from './hooks/useSuggestFormulaDescription';

const MIN_VISIBLE_EDITOR_LINES = 3;

type FormulaFieldSectionProps = {
  schemaContent: SchemaSection[];
  queueUrls: Url[];
  formulaSchemaId: string;
  onChange: () => void;
  disabled: boolean;
  fieldState: ControllerFieldState;
  field: {
    onBlur: () => void;
    onChange: (newValue: string) => void;
    value: string;
  };
};

export const FormulaFieldSection = ({
  schemaContent,
  queueUrls,
  formulaSchemaId,
  onChange,
  disabled,
  fieldState,
  field,
}: FormulaFieldSectionProps) => {
  const formula = field.value;
  const errorMessage =
    fieldState.invalid && fieldState.error ? fieldState.error.message : null;

  const formMethods = useFormContext();

  const [triggerCopilotOpen, setTriggerCopilotOpen] = useState(false);

  const [codeEditorVisible, setCodeEditorVisible] = useState(false);

  const [previewVisible, setPreviewVisible] = useState(false);

  const createDraftSchemaForPreview = (formula: string) =>
    formula
      ? {
          schemaContent: updateFieldFormula(
            schemaContent,
            formulaSchemaId,
            formula
          ),
          fieldSchemaId: formulaSchemaId,
        }
      : undefined;

  const [schemaForPreview, setSchemaForPreview] = useState(() =>
    createDraftSchemaForPreview(formula)
  );

  const { data: summary, isFetching: isSuggestingSummary } = useFormulaSummary(
    schemaForPreview,
    { enabled: !disabled && !errorMessage }
  );

  const intl = useIntl();

  return (
    <>
      <ContentWrapper>
        <SplitContent
          disabled={disabled}
          title={intl.formatMessage({
            id: 'features.formulas.formulaFieldSection.title',
          })}
          description={intl.formatMessage({
            id: 'features.formulas.formulaFieldSection.description',
          })}
        >
          {isSuggestingSummary ? (
            <SummaryBox variant="suggestingSummary" />
          ) : disabled ? (
            <SummaryBox variant="missingInfo" />
          ) : summary ? (
            <SummaryBox value={summary} />
          ) : formula ? (
            <SummaryBox variant="editingCode" />
          ) : (
            <SummaryBox
              variant="emptyFormula"
              callback={() => setTriggerCopilotOpen(true)}
            />
          )}
        </SplitContent>

        {disabled ? null : (
          <Stack>
            <Collapse in={codeEditorVisible}>
              <Stack spacing={2} pt={3}>
                <Divider />
                <Stack spacing={1}>
                  <FormulaEditor
                    placeholder={intl.formatMessage({
                      id: 'features.formulas.formulaFieldSection.formulaEditorPlaceholder',
                    })}
                    fields={getValidSchemaFields(schemaContent)}
                    value={formula}
                    onChange={newValue => {
                      onChange();
                      field.onChange(newValue);
                      // We need to validate syntax in formula editor on change in order to disable preview and apply code button
                      // Basically to avoid 400 requests to formulaInfo and evaluateFormulas
                      formMethods.trigger('field.formula');
                    }}
                    onBlur={field.onBlur}
                    config={{ minVisibleLines: MIN_VISIBLE_EDITOR_LINES }}
                    renderButtons={({ editor }) => (
                      <Stack direction="row" spacing={1}>
                        <TemplateSuggesterButton
                          renderContent={({ onInsert }) => (
                            <Suggester
                              onInsert={onInsert}
                              schemaContent={schemaContent}
                            />
                          )}
                          editor={editor}
                          disabled={false}
                        />

                        <Button
                          variant="outlined"
                          color="secondary"
                          disabled={
                            isSuggestingSummary ||
                            formula.length === 0 ||
                            !!errorMessage
                          }
                          startIcon={
                            isSuggestingSummary ? (
                              <CircularProgress size={18} />
                            ) : null
                          }
                          onClick={() => {
                            setSchemaForPreview(
                              createDraftSchemaForPreview(formula)
                            );
                          }}
                        >
                          {intl.formatMessage({
                            id: 'features.formulas.applyCode',
                          })}
                        </Button>
                      </Stack>
                    )}
                  />
                  {errorMessage ? (
                    <Typography color="error" variant="body2">
                      {errorMessage}
                    </Typography>
                  ) : null}
                </Stack>
              </Stack>
            </Collapse>
            <Collapse in={previewVisible}>
              <Stack spacing={2} pt={3}>
                <Divider />
                {schemaForPreview ? (
                  <Paper>
                    <FormulaPreviewGrid
                      queueUrls={queueUrls}
                      currentFormulaId={schemaForPreview.fieldSchemaId}
                      schemaForPreview={schemaForPreview.schemaContent}
                      disabled={!!errorMessage}
                      type="formula"
                    />
                  </Paper>
                ) : (
                  <Typography>
                    {intl.formatMessage({
                      id: 'features.formulas.formulaFieldSection.noFormula',
                    })}
                  </Typography>
                )}
              </Stack>
            </Collapse>
          </Stack>
        )}

        <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
          <Tooltip
            title={
              codeEditorVisible
                ? intl.formatMessage({
                    id: 'features.formulas.hideSourceCode',
                  })
                : intl.formatMessage({
                    id: 'features.formulas.showSourceCode',
                  })
            }
          >
            <IconButton
              disabled={disabled}
              color="inherit"
              onClick={() => setCodeEditorVisible(!codeEditorVisible)}
            >
              {codeEditorVisible ? <CodeOff /> : <Code />}
            </IconButton>
          </Tooltip>
          <Button
            variant="outlined"
            disabled={disabled || !formula}
            color="secondary"
            onClick={() => {
              if (previewVisible) {
                setPreviewVisible(false);
              } else {
                setSchemaForPreview(createDraftSchemaForPreview(formula));
                setPreviewVisible(true);
              }
            }}
          >
            {previewVisible
              ? intl.formatMessage({
                  id: 'features.formulas.formulaFieldSection.hideTest',
                })
              : intl.formatMessage({
                  id: 'features.formulas.formulaFieldSection.testFormula',
                })}
          </Button>
          <Button
            variant="contained"
            color="aurora"
            onClick={() => setTriggerCopilotOpen(true)}
            startIcon={
              <AuroraSuggest stroke={disabled ? undefined : 'white'} />
            }
            disabled={disabled}
          >
            {intl.formatMessage({
              id: 'features.formulas.copilotEdit',
            })}
          </Button>
        </Stack>
      </ContentWrapper>
      <FormulaCopilot
        key={triggerCopilotOpen.toString()}
        open={triggerCopilotOpen}
        onClose={() => setTriggerCopilotOpen(false)}
        onSuccess={response => {
          if (response?.formula) {
            onChange();
            field.onChange(response.formula);
            setSchemaForPreview(createDraftSchemaForPreview(response.formula));
          }
          setTriggerCopilotOpen(false);
        }}
        schemaContent={
          // Avoids error: "Datapoints of type 'formula' need to have a formula defined"
          // Current schemaContent can have formula undefined, make sure to append the actual value before sending the request.
          updateFieldFormula(schemaContent, formulaSchemaId, formula)
        }
        fieldSchemaId={formulaSchemaId}
        isIterativeApproach={!!formula}
      />
    </>
  );
};
