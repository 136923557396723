import { SchemaRule, SchemaRuleAction } from '../../../rules/models/schemaRule';
import { SchemaSection } from '../../../schemas/models/schemaSection';
import {
  suggestTriggerPayloadSchema,
  suggestTriggerResponseSchema,
} from './suggestTrigger.schema';

export type SuggestTriggerPayload = {
  userQuery: string;
  schemaContent: SchemaSection[];
  schemaRule: Partial<SchemaRule>;
};

export type SuggestTriggerResult = {
  triggerCondition?: string;
  triggerConditionSummary?: string;
};

export type SuggestTriggerResponse = {
  results: Array<SuggestTriggerResult>;
};

export const suggestTrigger = (payload: SuggestTriggerPayload) =>
  ({
    endpoint: `internal/schemas/suggest_trigger`,
    method: 'POST',
    responseSchema: suggestTriggerResponseSchema,
    payloadSchema: suggestTriggerPayloadSchema,
    payload,
  }) as const;
