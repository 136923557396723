import { endpoints, loadEtag } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../lib/apiClient';

export const SCHEMA_QUERY_KEY = 'schema';

export const useSchema = ({ schemaId }: { schemaId: number }) => {
  const api = useApiClient();
  return useQuery({
    queryKey: [SCHEMA_QUERY_KEY, schemaId] as const,
    queryFn: () =>
      api.fullRequest(endpoints.schemas.get(schemaId)).then(loadEtag),
    enabled: !!schemaId,
  });
};
