import { endpoints } from '@rossum/api-client';
import { SchemaSection } from '@rossum/api-client/schemas';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import { RuleFormType } from '../types';

export const useSuggestRuleTriggerSummary = <T extends RuleFormType>(
  payload:
    | {
        schemaContent: SchemaSection[];
        schemaRule: T;
      }
    | undefined,
  { enabled }: { enabled: boolean }
) => {
  const api = useApiClient();
  return useQuery({
    queryKey: ['rule-condition-summary', payload] as const,
    queryFn: () =>
      api.request(
        endpoints.internal.schemas.suggestRuleTriggerDescription(payload!)
      ),
    enabled: payload !== undefined && enabled,
    select: data => data?.results[0]?.triggerConditionSummary,
  });
};
