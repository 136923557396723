// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const emailSendPayloadAddressSchema = z.object({
  email: z.string(),
  name: z.string().optional(),
});

export const emailSendPayloadContentSchema = z.object({
  subject: z.string(),
  message: z.string(),
  rejectionNote: z.string().optional(),
});

export const emailSendPayloadSchema = z.object({
  to: z.array(emailSendPayloadAddressSchema),
  cc: z.array(emailSendPayloadAddressSchema).optional(),
  bcc: z.array(emailSendPayloadAddressSchema).optional(),
  parentEmail: urlSchema.optional(),
  templateValues: emailSendPayloadContentSchema,
  relatedAnnotations: z.array(urlSchema).optional(),
  relatedDocuments: z.array(urlSchema).optional(),
  attachments: z
    .object({
      documents: z.array(urlSchema),
    })
    .optional(),
  queue: urlSchema,
  labels: z.array(z.string()).optional(),
});

export const emailSendResponseSchema = z.object({
  email: urlSchema,
});
