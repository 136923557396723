import { SchemaAction } from '@rossum/api-client/schemas';
import { VisibilityOffOutlined, VisibilityOutlined } from '@rossum/ui/icons';
import { Autocomplete, TextField } from '@rossum/ui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { z } from 'zod';
import { getSchemaFields } from '../../formulas/FormulaEditor/helpers';
import { createForm } from './createForm';
import { ActionHeader, createHeader } from './createHeader';

const visibilityOptions = ['visible', 'hidden'] as const;

const visibilityActionPayloadSchema = z.object({
  visibility: z.enum(visibilityOptions).default('hidden'),
  schema_ids: z.array(z.string()).default([]),
});

const VisibilityActionForm = createForm(
  ({ value, onChange, schemaContent }) => {
    const intl = useIntl();
    const schemaFieldOptions = useMemo(
      () => getSchemaFields(schemaContent),
      [schemaContent]
    );
    const schemaField = useMemo(
      () => schemaFieldOptions.filter(f => value.schema_ids.includes(f.id)),
      [schemaFieldOptions, value.schema_ids]
    );
    return (
      <Autocomplete
        multiple
        size="small"
        options={schemaFieldOptions}
        getOptionLabel={option => `${option.label} (${option.id})`}
        value={schemaField}
        onChange={(_e, vs) => {
          onChange({ ...value, schema_ids: vs.map(v => v.id) });
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: 'features.queueSettings.rules.actions.visibility.form.fields',
            })}
            placeholder={intl.formatMessage({
              id: 'features.queueSettings.rules.actions.visibility.form.chooseFields',
            })}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    );
  },
  visibilityActionPayloadSchema
);

const VisibilityActionHeader = createHeader(
  ({ value }) => (
    <ActionHeader
      entries={[
        {
          key: 'schema_ids',
          value: value.schema_ids.join(', '),
        },
      ]}
    />
  ),
  visibilityActionPayloadSchema
);

const showFieldAction = {
  icon: VisibilityOutlined,
  intlKey: 'showField' as const,
  resolveAction: (a: SchemaAction) =>
    a.type === 'visibility' &&
    visibilityActionPayloadSchema.parse(a.payload).visibility === 'visible',
  serialized: { type: 'visibility', payload: { visibility: 'visible' } },
  groupIntlKey: 'visibility' as const,
  form: VisibilityActionForm,
  header: VisibilityActionHeader,
};

const hideFieldAction = {
  icon: VisibilityOffOutlined,
  intlKey: 'hideField' as const,
  resolveAction: (a: SchemaAction) =>
    a.type === 'visibility' &&
    visibilityActionPayloadSchema.parse(a.payload).visibility === 'hidden',
  serialized: { type: 'visibility', payload: { visibility: 'hidden' } },
  groupIntlKey: 'visibility' as const,
  form: VisibilityActionForm,
  header: VisibilityActionHeader,
};

export const visibilityActions = [showFieldAction, hideFieldAction];
