import { SchemaRule, SchemaRuleAction } from '../../../rules/models/schemaRule';
import { SchemaSection } from '../../../schemas/models/schemaSection';
import {
  suggestRuleTriggerDescriptionPayloadSchema,
  suggestRuleTriggerDescriptionResponseSchema,
} from './suggestRuleTriggerDescription.schema';

export type SuggestRuleTriggerDescriptionPayload = {
  userQuery?: string;
  schemaContent: SchemaSection[];
  schemaRule: Partial<SchemaRule>;
};

export type SuggestTriggerResult = {
  description?: string;
  enabled?: boolean;
  triggerCondition?: string;
  triggerConditionSummary?: string;
  name?: string;
  ruleActions?: Partial<SchemaRuleAction>[];
};

export type SuggestRuleTriggerDescriptionResponse = {
  results: Array<SuggestTriggerResult>;
};

export const suggestRuleTriggerDescription = (
  payload: SuggestRuleTriggerDescriptionPayload
) =>
  ({
    endpoint: `internal/schemas/suggest_rule_trigger_description`,
    method: 'POST',
    responseSchema: suggestRuleTriggerDescriptionResponseSchema,
    payloadSchema: suggestRuleTriggerDescriptionPayloadSchema,
    payload,
  }) as const;
