import { Schema } from '@rossum/api-client/schemas';
import { DeleteOutline } from '@rossum/ui/icons';
import { Button, Skeleton } from '@rossum/ui/material';
import { Location } from 'history';
import { random } from 'lodash';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { stringify } from '../../../lib/url';
import { getFlattenSchemaFields } from '../../schemas/getFlattenSchemaFields';
import { DELETE_FIELD_FORM_ID, FIELD_FORM_ID } from '../fields/constants';
import { replaceUrlSegment } from '../fields/helpers';

// TODO: move to utils
const truncateString = (
  str: string,
  maxLength: number,
  partsLength: number
) => {
  if (str.length <= maxLength) {
    return str;
  }

  const firstPart = str.slice(0, partsLength);
  const lastPart = str.slice(-partsLength);

  return `${firstPart}...${lastPart}`;
};

export const useFieldsHeaderMeta = (
  parentPath: string,
  subPageKeys: string[],
  schema: Schema | undefined,
  schemaIsLoading: boolean
) => {
  const intl = useIntl();

  const history = useHistory();

  const isListPage = subPageKeys.length === 0;

  const fieldsIdsMap = getFlattenSchemaFields(schema?.content || []);

  const canReorderSections = (schema?.content?.length ?? 0) > 1;

  const currentField = subPageKeys.length
    ? fieldsIdsMap[subPageKeys.at(-1) ?? '']?.field
    : null;

  const isNewField = currentField === null || currentField === undefined;

  const dynamicFieldsBreadcrumbs = subPageKeys.map(key => {
    const field = fieldsIdsMap[key];

    const newEntityLabel =
      subPageKeys.length === 1
        ? intl.formatMessage({
            id: 'features.queueSettings.fields.breadcrumbs.newSection',
          })
        : intl.formatMessage({
            id: 'features.queueSettings.fields.breadcrumbs.newField',
          });

    return {
      value: key,
      label: field ? (
        truncateString(field.field.label, 25, 10)
      ) : schemaIsLoading ? (
        <Skeleton width={random(30, 100)} />
      ) : (
        newEntityLabel
      ),
      to: field
        ? `${parentPath}/${field.path?.map(field => field.id).join('/')}`
        : `${parentPath}`,
      isLink: true,
    };
  });

  const backLink =
    subPageKeys.length === 3 && dynamicFieldsBreadcrumbs[1]
      ? dynamicFieldsBreadcrumbs[1].to
      : `queues/${parentPath}/settings/fields`;

  const getFieldLabel = (fieldId: string) => {
    const field = fieldsIdsMap[fieldId];

    return field ? field.field.label : '';
  };

  const title = isListPage
    ? intl.formatMessage({
        id: 'features.queueSettings.fields.title',
      })
    : getFieldLabel(subPageKeys.at(-1) ?? '');

  const description = isListPage
    ? intl.formatMessage({
        id: 'features.queueSettings.fields.description',
      })
    : '';

  const onAddSection = useCallback(() => {
    const temporarySchemaSectionId = uuidv4();

    history.push({
      pathname: `${history.location.pathname}/${temporarySchemaSectionId}`,
      state: { backLink: history.location.pathname },
    });
  }, [history]);

  const onReorderingDone = useCallback(() => {
    history.push({
      pathname: location.pathname,
      search: undefined,
      state: { backLink: location.pathname },
    });
  }, [history]);

  const reorderingSections =
    history.location.search.includes('reorder-sections');

  const buttons = isListPage
    ? reorderingSections
      ? [
          <Button
            key="section-reordering-done"
            variant="contained"
            onClick={onReorderingDone}
          >
            {intl.formatMessage({
              id: 'features.queueSettings.actions.reorderingDone',
            })}
          </Button>,
        ]
      : [
          <Button
            key="json-schema"
            data-cy="edit-json-schema"
            component={Link}
            to={(location: Location) => ({
              pathname: `/${replaceUrlSegment(location.pathname ?? '', 'schema', 1)}`,
              state: { backLink: location.pathname },
            })}
            variant="outlined"
            color="secondary"
            sx={{
              '&:hover': {
                color: theme => theme.palette.secondary.main,
                textDecoration: 'none',
              },
            }}
          >
            {intl.formatMessage({
              id: 'containers.settings.queues.queue.editSchema',
            })}
          </Button>,
          canReorderSections ? (
            <Button
              key="reorder-sections"
              data-cy="reorder-sections-button"
              variant="outlined"
              color="secondary"
              component={Link}
              to={(location: Location) => {
                return {
                  pathname: location.pathname,
                  search: stringify({ mode: 'reorder-sections' }),
                  state: { backLink: location.pathname },
                };
              }}
              sx={{
                '&:hover, &:focus': {
                  color: theme => theme.palette.secondary.main,
                  textDecoration: 'none',
                },
              }}
            >
              {intl.formatMessage({
                id: 'features.queueSettings.actions.reorderSections',
              })}
            </Button>
          ) : null,
          <Button
            key="add-section"
            variant="contained"
            onClick={onAddSection}
            data-cy="add-section-button"
          >
            {intl.formatMessage({
              id: 'features.queueSettings.actions.addSection',
            })}
          </Button>,
        ]
    : [
        // Edit JSON Button should be hidden when field is created from field manager
        !subPageKeys ? null : (
          <Button
            key="json-schema"
            data-cy="edit-json-schema"
            component={Link}
            to={(location: Location) => {
              const searchQuery = currentField?.id
                ? `id=${currentField.id}`
                : '';

              const allSegments = location.pathname.split('/');
              const fieldsIndex = allSegments.indexOf('fields');

              const segmentsToDrop = allSegments.slice(fieldsIndex).length;

              return {
                pathname: `/${replaceUrlSegment(
                  location.pathname ?? '',
                  `schema`,
                  segmentsToDrop
                )}`,
                state: { backLink: location.pathname },
                search: searchQuery,
              };
            }}
            variant="outlined"
            color="secondary"
            sx={{
              '&:hover': {
                color: theme => theme.palette.secondary.main,
                textDecoration: 'none',
              },
            }}
          >
            {intl.formatMessage({
              id: 'containers.settings.queues.queue.editSchema',
            })}
          </Button>
        ),

        // In case that field is not created user should be redirected back when clicks on the button
        isNewField ? (
          <Button
            key="cancel-field"
            component={Link}
            to={() => {
              return { pathname: backLink };
            }}
            variant="outlined"
            color="secondary"
            startIcon={<DeleteOutline />}
            data-cy="cancel-field-button"
          >
            {intl.formatMessage({
              id: 'features.queueSettings.actions.delete',
            })}
          </Button>
        ) : (
          <Button
            key="remove-field"
            variant="outlined"
            color="secondary"
            startIcon={<DeleteOutline />}
            type="submit"
            data-cy="remove-field-button"
            form={DELETE_FIELD_FORM_ID}
          >
            {intl.formatMessage({
              id: 'features.queueSettings.actions.delete',
            })}
          </Button>
        ),
        <Button
          key="submit-field-form"
          variant="contained"
          type="submit"
          form={FIELD_FORM_ID}
        >
          {intl.formatMessage({
            id: 'features.queueSettings.actions.save',
          })}
        </Button>,
      ];

  // TODO: Now we can play with this to improve functionality
  const onBackButtonClicked = useCallback(() => {
    // If the queue settings page was opened in a new tab, there is no history stack to go back, so fallbacking to "/documents" path instead
    if (history.length > 1) {
      return history.goBack();
    }
    return history.push('/documents');
  }, [history]);

  return {
    breadcrumbs: dynamicFieldsBreadcrumbs,
    title,
    description,
    buttons,
    onBackButtonClicked,
  };
};
