import { zodResolver } from '@hookform/resolvers/zod';
import { ID, WithEtag } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { Schema, SchemaRule } from '@rossum/api-client/schemas';
import { ToggleOffOutlined, ToggleOnOutlined } from '@rossum/ui/icons';
import {
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Stack,
} from '@rossum/ui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router';
import { z } from 'zod';
import { PageLayoutV2 } from '../../../../components/PageLayoutV2/PageLayoutV2';
import {
  throwError,
  throwInfo,
} from '../../../../redux/modules/messages/actions';
import { Header } from '../../../../ui/header/Header';
import { LeavingDialog } from '../../../../ui/leaving-dialog/LeavingDialog';
import { InitialCopilot } from '../../../rules/copilots/InitialCopilot';
import { RuleTemplate } from '../../../rules/hooks/useSuggestRule';
import { RuleFormType } from '../../../rules/types';
import { QueueSettingsBreadcrumbs } from '../../components/QueueSettingsBreadcrumbs';
import { useCreateRule } from '../hooks/useCreateRule';
import { useDeleteRule } from '../hooks/useDeleteRule';
import { useGetRule } from '../hooks/useGetRule';
import { usePatchRule } from '../hooks/usePatchRule';
import { RuleForm } from './RuleForm';

const RULE_FORM_ID = 'rule_form';

type RulePageProps = {
  rule?: SchemaRule;
  schema: WithEtag<Schema>;
  queue: Queue;
  parentPath: string;
};

export const ruleFormSchema = (intl: IntlShape) =>
  z.object({
    name: z.string().min(
      1,
      intl.formatMessage({
        id: 'features.queueSettings.rules.error.form.fieldRequired',
      })
    ),
    description: z.string(),
    triggerCondition: z
      .string()
      .min(
        1,
        intl.formatMessage({
          id: 'features.queueSettings.rules.error.form.fieldRequired',
        })
      )
      .max(
        500,
        intl.formatMessage({
          id: 'features.queueSettings.rules.error.form.formulaLength',
        })
      ),
    enabled: z.boolean(),
    ruleActions: z.array(
      z.object({
        id: z.string(),
        enabled: z.boolean(),
        action: z.object({
          type: z.string(),
          payload: z.unknown(),
        }),
      })
    ),
  });

const useRuleForm = ({ rule }: { rule?: SchemaRule | RuleTemplate }) => {
  const intl = useIntl();
  return useForm<z.TypeOf<ReturnType<typeof ruleFormSchema>>>({
    defaultValues: rule ?? {
      name: '',
      description: '',
      triggerCondition: '',
      enabled: true,
      ruleActions: [],
    },
    resolver: zodResolver(ruleFormSchema(intl)),
  });
};

export const RulePage = ({
  rule,
  schema,
  queue,
  parentPath,
}: RulePageProps) => {
  const intl = useIntl();
  const history = useHistory();

  const dispatch = useDispatch();
  const { hash } = useLocation();

  const { mutate: patchRule, isLoading: isPatching } = usePatchRule();

  const { mutate: createRule, isLoading: isCreating } = useCreateRule(
    schema.url
  );

  const { mutate: deleteRule, isLoading: isDeleting } = useDeleteRule();

  const formMethods = useRuleForm({ rule });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, isSubmitSuccessful },
  } = formMethods;

  const onDeleteSuccess = useCallback(() => {
    dispatch(throwInfo('ruleDeleteSuccess'));

    return history.replace(`${parentPath}/rules`);
  }, [dispatch, history, parentPath]);

  const onDeleteError = useCallback(
    () => dispatch(throwError('ruleDeleteFailed')),
    [dispatch]
  );

  const buttons = [
    !rule ? null : (
      <Controller
        control={control}
        name="enabled"
        render={({ field }) => (
          <IconButton size="small" onClick={() => field.onChange(!field.value)}>
            {field.value ? (
              <ToggleOnOutlined color="success" />
            ) : (
              <ToggleOffOutlined />
            )}
          </IconButton>
        )}
      />
    ),
    !rule ? null : (
      <Button
        key="delete-rule-button"
        data-cy="delete-rule-btn"
        variant="outlined"
        color="secondary"
        startIcon={isDeleting && <CircularProgress color="inherit" size={20} />}
        disabled={isDeleting}
        onClick={() =>
          deleteRule(
            {
              deletedRuleId: rule.id,
            },
            {
              onSuccess: onDeleteSuccess,
              onError: onDeleteError,
            }
          )
        }
      >
        {intl.formatMessage({
          id: 'features.queueSettings.rules.detail.delete',
        })}
      </Button>
    ),
    <Button
      key="save-rule-button"
      data-cy="save-rule-btn"
      variant="contained"
      type="submit"
      startIcon={
        (isPatching || isCreating) && (
          <CircularProgress color="inherit" size={20} />
        )
      }
      disabled={!isDirty || isPatching || isCreating}
      form={RULE_FORM_ID}
    >
      {intl.formatMessage({
        id: 'features.queueSettings.rules.detail.save',
      })}
    </Button>,
  ];

  const enabled = watch('enabled');
  const name = watch('name');
  const description = watch('description');

  const onSubmit = async (formValues: RuleFormType) => {
    const payload: RuleFormType = {
      ...formValues,
    };

    return rule
      ? patchRule(
          {
            patchedRuleId: rule.id,
            patchedRule: payload,
          },
          {
            onSuccess: () => {
              return dispatch(throwInfo('ruleUpdateSuccess'));
            },
            onError: () => dispatch(throwError('ruleUpdateFailed')),
          }
        )
      : createRule(payload, {
          onSuccess: createdRule => {
            history.replace(`${createdRule.id}/detail`);
            dispatch(throwInfo('ruleCreateSuccess'));
          },
          onError: () => dispatch(throwError('ruleCreationFailed')),
        });
  };

  useEffect(() => {
    // Reset form state when form is submitted (so that you don't see leaving dialog)
    // TODO: isSubmitSuccessful does not actually check success now at all.
    reset(undefined, { keepValues: true });
  }, [isSubmitSuccessful, reset]);

  const [initialCopilotOpen, setInitialCopilotOpen] = useState<boolean>(
    rule === undefined && hash === '#copilot'
  );

  const onBackButtonClicked = useCallback(() => {
    return history.push(`${parentPath}/rules`);
  }, [history, parentPath]);

  return (
    <PageLayoutV2
      renderHeader={params => (
        <Header
          scrollableDivRef={params.scrollableDivRef}
          onBackButtonClicked={onBackButtonClicked}
          breadcrumbs={
            <QueueSettingsBreadcrumbs
              breadcrumbs={[
                {
                  label: intl.formatMessage({
                    id: 'features.queueSettings.rules.list.title',
                  }),
                  to: `${parentPath}/rules`,
                },
                {
                  label:
                    rule?.name ??
                    intl.formatMessage({
                      id: 'features.queueSettings.rules.newRule.title',
                    }),
                },
              ]}
              queueName={queue.name}
              settingsPath={parentPath}
            />
          }
          buttons={buttons}
          title={name}
          description={description}
        />
      )}
    >
      <Stack
        px={4}
        py={4}
        spacing={4}
        id={RULE_FORM_ID}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <InitialCopilot
          schemaContent={schema.content ?? []}
          onSuccess={value => {
            // keepDefaultValues ensures that isDirty is set properly for leaving dialog
            reset(value, { keepDefaultValues: true });
            history.replace({ hash: undefined });
            setInitialCopilotOpen(false);
          }}
          onClose={() => {
            history.replace({ hash: undefined });
            setInitialCopilotOpen(false);
          }}
          open={initialCopilotOpen}
        />

        {initialCopilotOpen ? null : (
          <FormProvider {...formMethods}>
            <RuleForm control={control} enabled={enabled} schema={schema} />
          </FormProvider>
        )}

        <LeavingDialog when={isDirty} />
      </Stack>
    </PageLayoutV2>
  );
};

export const RulePageDetail = ({
  ruleId,
  schema,
  queue,
  parentPath,
}: RulePageProps & { ruleId: ID }) => {
  const { data: rule, isLoading } = useGetRule(ruleId);

  return rule ? (
    <RulePage
      schema={schema}
      queue={queue}
      parentPath={parentPath}
      rule={rule}
    />
  ) : isLoading ? (
    <LinearProgress />
  ) : (
    <Redirect to={`${parentPath}/rules`} />
  );
};
