import { Stack } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { DatapointColumn, RowModel } from '../hooks/useRows';

export const ConditionCell = ({
  value,
}: {
  value: NonNullable<RowModel[DatapointColumn]>;
}) => {
  const intl = useIntl();

  if (!value) return null;

  const [first] = value.datapoints;

  const enabled =
    first && 'content' in first && first.content
      ? first.content.value === 'True'
      : undefined;

  return enabled === undefined ? null : (
    <Stack direction="row" spacing={1} width="100%">
      {enabled ? intl.formatMessage({ id: 'features.formulas.matched' }) : null}
    </Stack>
  );
};
