import { SchemaAction } from '@rossum/api-client/schemas';
import {
  IconBan,
  IconCircleCheck,
  IconClock,
  IconShare2,
  IconTrash,
} from '@rossum/ui/icons/tabler';
import { createSvgIcon } from '@rossum/ui/material';
import { z } from 'zod';

const actions = ['postponed', 'export', 'delete', 'confirm', 'reject'] as const;

const documentStateActionPayloadSchema = z.object({
  action: z.enum(actions).nullable().default(null),
});

export const postponeAction = {
  icon: createSvgIcon(<IconClock />, 'IconClock'),
  intlKey: 'documentAction.postpone' as const,
  groupIntlKey: 'documentActions' as const,
  resolveAction: (a: SchemaAction) =>
    a.type === 'document_state' &&
    documentStateActionPayloadSchema.parse(a.payload).action === 'postponed',
  serialized: { type: 'document_state', payload: { action: 'postponed' } },
  form: () => null,
  header: () => null,
};

export const exportAction = {
  icon: createSvgIcon(<IconShare2 />, 'IconShare2'),
  intlKey: 'documentAction.export' as const,
  groupIntlKey: 'documentActions' as const,
  resolveAction: (a: SchemaAction) =>
    a.type === 'document_state' &&
    documentStateActionPayloadSchema.parse(a.payload).action === 'export',
  serialized: { type: 'document_state', payload: { action: 'export' } },
  form: () => null,
  header: () => null,
};

export const deleteAction = {
  icon: createSvgIcon(<IconTrash />, 'IconTrash'),
  intlKey: 'documentAction.delete' as const,
  groupIntlKey: 'documentActions' as const,
  resolveAction: (a: SchemaAction) =>
    a.type === 'document_state' &&
    documentStateActionPayloadSchema.parse(a.payload).action === 'delete',
  serialized: { type: 'document_state', payload: { action: 'delete' } },
  form: () => null,
  header: () => null,
};

export const confirmAction = {
  icon: createSvgIcon(<IconCircleCheck />, 'IconCircleCheck'),
  intlKey: 'documentAction.confirm' as const,
  groupIntlKey: 'documentActions' as const,
  resolveAction: (a: SchemaAction) =>
    a.type === 'document_state' &&
    documentStateActionPayloadSchema.parse(a.payload).action === 'confirm',
  serialized: { type: 'document_state', payload: { action: 'confirm' } },
  form: () => null,
  header: () => null,
};

export const rejectAction = {
  icon: createSvgIcon(<IconBan />, 'IconBan'),
  intlKey: 'documentAction.reject' as const,
  groupIntlKey: 'documentActions' as const,
  resolveAction: (a: SchemaAction) =>
    a.type === 'document_state' &&
    documentStateActionPayloadSchema.parse(a.payload).action === 'reject',
  serialized: { type: 'document_state', payload: { action: 'reject' } },
  form: () => null,
  header: () => null,
};

export const documentStateActions = [
  postponeAction,
  exportAction,
  deleteAction,
  confirmAction,
  rejectAction,
];
