import { SchemaAction } from '@rossum/api-client/schemas';
import { FontDownloadOutlined } from '@rossum/ui/icons';
import { Autocomplete, TextField } from '@rossum/ui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { z } from 'zod';
import { getSchemaFields } from '../../formulas/FormulaEditor/helpers';
import { createForm } from './createForm';
import { ActionHeader, createHeader } from './createHeader';

const automationBlockerActionPayloadSchema = z.object({
  content: z.string().default(''),
  schema_id: z.string().nullable().default(null),
});

const AutomationBlockerActionForm = createForm(
  ({ value, onChange, schemaContent }) => {
    const intl = useIntl();
    const schemaFieldOptions = useMemo(
      () => getSchemaFields(schemaContent),
      [schemaContent]
    );
    const schemaField = useMemo(
      () => schemaFieldOptions.find(f => f.id === value.schema_id),
      [schemaFieldOptions, value.schema_id]
    );

    return (
      <>
        <TextField
          label={intl.formatMessage({
            id: 'features.queueSettings.rules.actions.automationBlocker.form.content',
          })}
          size="small"
          multiline
          maxRows={10}
          value={value.content}
          onChange={e => onChange({ ...value, content: e.currentTarget.value })}
        />
        <Autocomplete
          size="small"
          options={schemaFieldOptions}
          getOptionLabel={option => `${option.label} (${option.id})`}
          value={schemaField}
          onChange={(_e, v) => {
            if (v) {
              onChange({ ...value, schema_id: v.id });
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={intl.formatMessage({
                id: 'features.queueSettings.rules.actions.automationBlocker.form.placement',
              })}
              placeholder={intl.formatMessage({
                id: 'features.queueSettings.rules.actions.automationBlocker.form.placementNone',
              })}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </>
    );
  },
  automationBlockerActionPayloadSchema
);

const AutomationBlockerActionHeader = createHeader(
  ({ value }) => (
    <ActionHeader
      entries={[
        {
          key: 'content',
          value: value.content,
          sx: {
            maxWidth: 200,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
        },
      ]}
    />
  ),
  automationBlockerActionPayloadSchema
);

export const automationBlockerAction = {
  icon: FontDownloadOutlined,
  intlKey: 'automationBlocker' as const,
  resolveAction: (a: SchemaAction) => a.type === 'automation_blocker',
  serialized: { type: 'automation_blocker' },
  groupIntlKey: 'messages' as const,
  form: AutomationBlockerActionForm,
  header: AutomationBlockerActionHeader,
};
