import { SchemaRuleAction } from '../../../rules/models/schemaRule';
import { SchemaSection } from '../../../schemas/models/schemaSection';
import {
  suggestRulePayloadSchema,
  suggestRuleResponseSchema,
} from './suggestRule.schema';

export type SuggestRulePayload = {
  userQuery: string;
  schemaContent: SchemaSection[];
};

export type SuggestRuleResult = {
  description?: string;
  enabled?: boolean;
  id?: string;
  triggerCondition?: string;
  triggerConditionSummary?: string;
  name?: string;
  ruleActions?: Partial<SchemaRuleAction>[];
};

export type SuggestRuleResponse = {
  results: Array<SuggestRuleResult>;
};

export const suggestRule = (payload: SuggestRulePayload) =>
  ({
    endpoint: `internal/schemas/suggest_rule`,
    method: 'POST',
    responseSchema: suggestRuleResponseSchema,
    payloadSchema: suggestRulePayloadSchema,
    payload,
  }) as const;
