// Generated by ts-to-zod
import { z } from 'zod';

export const annotationsCrossQueueQuerySchema = z
  .object({
    format: z.string(),
    appendColumns: z.string(),
    prependColumns: z.string(),
    columns: z.string(),
    pageSize: z.number(),
    toStatus: z.literal('exported').optional(),
  })
  .partial();

export const annotationsCrossQueuePayloadSchema = z
  .object({
    annotations: z.array(z.string()),
  })
  .partial();
