import { getIDFromUrl } from '@rossum/api-client';
import { LinearProgress, Stack } from '@rossum/ui/material';
import {
  GridFilterItem,
  GridPaginationModel,
  GridSortModel,
} from '@rossum/ui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import TrialOnboarding from '../../containers/TrialOnboarding';
import { isTrialSelector } from '../../redux/modules/organization/selectors';
import { firstTimeUserSelector } from '../../redux/modules/user/selectors';
import { DocumentDrawer } from '../document/DocumentDrawer';
import {
  DEFAULT_PAGE_SIZE,
  usePagination,
} from '../document-list-base/hooks/usePagination';
import {
  createNewFilterQuery,
  getUpdatedExistingFilter,
} from '../document-list-base/mql/transformers';
import {
  SupportedAnnotationView,
  supportedAnnotationViews,
} from '../document-list-base/supportedAnnotationViews';
import AddQueueDialog from '../queue-sidebar/add-queue/AddQueueDialog';
import Sidebar, {
  DashboardModalsState,
  SIDEBAR_WIDTH,
} from '../queue-sidebar/sidebar';
import AddWorkspaceModal from '../queue-sidebar/sidebar/components/AddWorkspaceModal';
import { useWorkspacesWithQueues } from '../queues/hooks/useWorkspacesWithQueues';
import { dashboardResearchCallSurveyBlockersSelector } from '../surveys/ResearchCallSurvey/blockersSelectors';
import ResearchCallSurvey from '../surveys/ResearchCallSurvey/ResearchCallSurvey';
import { decodeSortModel, encodeSortModel } from './columns/ordering/helpers';
import DocumentsContent from './DocumentsContent';
import { useFetchEmailThreadsCounts } from './emails/hooks/useFetchEmailThreadsCounts';
import EmailsContent from './EmailsContent';
import { getPageSizeFromQuery } from './helpers';
import { useIsUserRestrictedToAllDocsLevel } from './hooks/useAreViewersRestricted';
import {
  DashboardQuery,
  LevelOptions,
  useDashboardQuery,
} from './hooks/useDashboardQuery';
import { useInvalidateDashboardData } from './hooks/useFetchDashboardData';
import useSidebarState from './hooks/useSidebarState';
import { useValidateDashboardLevel } from './hooks/useValidateDashboardLevel';
import AnnotationActionsContext from './selection-panel/AnnotationActionContext';

const CONTENT_PADDING = 4;

const DocumentList = () => {
  const { isUserRestrictedToAllDocsLevel } =
    useIsUserRestrictedToAllDocsLevel();

  const [dashboardState, setDashboardState] = useState<DashboardModalsState>({
    addingQueue: false,
    addingWorkspace: false,
    dialogResetCounter: 0,
  });

  const { isSidebarOpen, toggleSidebar } = useSidebarState();

  const { isLoading, queues } = useWorkspacesWithQueues({
    enableQueries: true,
  });

  const {
    query,
    setQuery,
    view,
    setView,
    currentQueueId,
    level,
    existingFilter,
  } = useDashboardQuery();

  const activeQueueName =
    queues?.find(queue => queue.id === currentQueueId)?.name ?? null;

  useEffect(() => {
    if (isUserRestrictedToAllDocsLevel && level !== 'all') {
      setQuery(prev => ({ ...prev, level: 'all' }));
    } else if (level === 'initial' && !currentQueueId && queues) {
      const firstQueueId = queues[0]?.id;

      if (firstQueueId) {
        setQuery(prevQuery => ({
          ...prevQuery,
          level: 'queue',
          filtering: createNewFilterQuery([
            {
              field: 'queue',
              operator: 'isAnyOf',
              value: [`${firstQueueId}`],
            },
          ]),
        }));
      } else {
        setQuery(prev => ({ ...prev, level: 'all' }));
      }
    }
  }, [level, currentQueueId, setQuery, queues, isUserRestrictedToAllDocsLevel]);

  const { data: counts } = useFetchEmailThreadsCounts({
    queueId: currentQueueId,
  });

  const newEmailsExist = !!counts?.withNewReplies;

  useValidateDashboardLevel({
    isUserRestrictedToAllDocsLevel,
    existingFilter,
    levelQuery: query.level,
    onFallback: validLevel =>
      setQuery(prev => ({ ...prev, level: validLevel })),
  });

  const onPaginationReset = useCallback(
    () => setQuery(prevQuery => ({ ...prevQuery, page: '1' })),
    [setQuery]
  );

  const onPaginationChange = useCallback(
    (newPaginationModel: GridPaginationModel) =>
      setQuery(prevQuery => ({
        ...prevQuery,
        page: `${newPaginationModel.page + 1}`,
        page_size: `${newPaginationModel.pageSize}`,
      })),
    [setQuery]
  );

  const paginationProps = usePagination({
    page: query.page ? parseInt(query.page, 10) : null,
    pageSize: getPageSizeFromQuery({ query, fallbackValue: DEFAULT_PAGE_SIZE }),
    onPaginationReset,
    onPaginationChange,
    disableResetOnMissingPage: view === 'emails',
  });

  const { resetPagination } = paginationProps;

  const onSortModelChange = ([sortColumn]: GridSortModel) =>
    handleQueryChange({
      ordering: sortColumn ? encodeSortModel(sortColumn) : undefined,
    });

  const sortModel: GridSortModel = useMemo(() => {
    if (!query.ordering) {
      return [];
    }

    return decodeSortModel(query.ordering);
  }, [query.ordering]);

  const sortingProps = {
    sortModel,
    onSortModelChange,
  };

  const onSearchSubmit = (searchValue: string | undefined) =>
    handleQueryChange({ search: searchValue }, searchValue !== query.search);

  const handleQueryChange = useCallback(
    (newQuery: DashboardQuery, shouldResetPagination = true) => {
      if (shouldResetPagination) resetPagination();

      setQuery(prevQuery => {
        const mergedQuery = {
          ...prevQuery,
          ...newQuery,
        };
        return Object.fromEntries(
          Object.entries(mergedQuery).filter(([_, value]) => Boolean(value))
        );
      });
    },
    [resetPagination, setQuery]
  );

  const handleFilterModelChange = useCallback(
    (newFilterItems: GridFilterItem[]) => {
      const shouldClearFilter = newFilterItems.length === 0;

      return handleQueryChange({
        filtering: shouldClearFilter
          ? undefined
          : createNewFilterQuery(newFilterItems),
      });
    },
    [handleQueryChange]
  );

  const handleClearAllFilters = useCallback(
    () =>
      handleFilterModelChange(
        existingFilter?.items.filter(filter => filter.field === query.level) ??
          []
      ),
    [existingFilter?.items, handleFilterModelChange, query.level]
  );

  const handleLevelChange = useCallback(
    (newLevel: LevelOptions) => {
      handleQueryChange({
        level: newLevel,
      });
    },
    [handleQueryChange]
  );

  const onQueueChange = (queueId: number) => {
    const existingFilterItems = existingFilter?.items ?? [];

    const incomingFilters: GridFilterItem[] = [
      {
        field: 'queue',
        value: [`${queueId}`],
        operator: 'isAnyOf',
      },
    ];

    handleFilterModelChange(
      getUpdatedExistingFilter({
        existingFilter,
        incomingFilters,
        isReplaceAction: existingFilterItems.some(
          item => item.field === 'queue'
        ),
      })
    );

    handleLevelChange('queue');
  };

  const firstTimeUser = useSelector(firstTimeUserSelector);
  const isTrial = useSelector(isTrialSelector);
  const showTrialOnboarding = isTrial && firstTimeUser && !isLoading;

  const handleSelectAnnotation = useCallback(
    (params: { annotationUrl: string; view: SupportedAnnotationView }) =>
      setQuery(prevQuery => ({
        ...prevQuery,
        selected_annotation: getIDFromUrl(params.annotationUrl).toString(),
        selected_annotation_view: params.view,
      })),
    [setQuery]
  );

  const invalidateDashboardData = useInvalidateDashboardData();

  return (
    <Stack flexDirection="row" width={1} height={1} flex={1}>
      {!isUserRestrictedToAllDocsLevel && (
        <Sidebar
          currentQueueId={currentQueueId}
          isSidebarOpen={isSidebarOpen}
          setDashboardState={setDashboardState}
          onQueueChange={onQueueChange}
          setAllDocumentsLevelActive={() => {
            handleQueryChange({
              filtering: undefined,
              view: undefined,
              level: 'all',
            });
          }}
          level={level}
        />
      )}
      <Stack
        p={CONTENT_PADDING}
        pb={0}
        flex={1}
        width={`calc(100% - ${SIDEBAR_WIDTH}px)`}
        height={1}
        sx={{ backgroundColor: t => t.palette.background.default }}
      >
        {level === 'initial' ? (
          // TODO: @Dual Don Skeleton will add a proper skeleton
          <LinearProgress sx={{ m: -CONTENT_PADDING }} />
        ) : view === 'emails' && level === 'queue' ? (
          <EmailsContent
            isSidebarEnabled={!isUserRestrictedToAllDocsLevel}
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
            view={view}
            setView={setView}
            level={level}
            activeQueueId={currentQueueId}
            newEmailsExist={newEmailsExist}
            onPaginationChange={onPaginationChange}
            query={query}
            sortingProps={sortingProps}
            onSearchSubmit={onSearchSubmit}
            existingFilter={existingFilter}
            handleFilterModelChange={handleFilterModelChange}
            handleClearAllFilters={handleClearAllFilters}
          />
        ) : (
          <AnnotationActionsContext activeQueueName={activeQueueName}>
            <DocumentDrawer
              onClose={() => {
                invalidateDashboardData();
                setQuery(prevQuery => ({
                  ...prevQuery,
                  // Reset annotation and view
                  selected_annotation: undefined,
                  selected_annotation_view: undefined,
                }));
              }}
              selectedAnnotationId={
                Number(query.selected_annotation) || undefined
              }
              selectedAnnotationView={supportedAnnotationViews.find(
                view => view === query.selected_annotation_view
              )}
              handleSelectAnnotation={handleSelectAnnotation}
            />
            <DocumentsContent
              isSidebarEnabled={!isUserRestrictedToAllDocsLevel}
              activeQueueId={currentQueueId}
              paginationProps={paginationProps}
              handleQueryChange={handleQueryChange}
              handleFilterModelChange={handleFilterModelChange}
              handleClearAllFilters={handleClearAllFilters}
              existingFilter={existingFilter}
              query={query}
              handleSelectAnnotation={handleSelectAnnotation}
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
              view={view}
              setView={setView}
              level={level}
              newEmailsExist={newEmailsExist}
              sortingProps={sortingProps}
              onSearchSubmit={onSearchSubmit}
              openQueueModal={() =>
                setDashboardState(prev => ({ ...prev, addingQueue: true }))
              }
            />
          </AnnotationActionsContext>
        )}
      </Stack>
      {showTrialOnboarding && <TrialOnboarding />}
      {/* `dialogResetCounter` is for resetting internal states of dialogs without explicitly unmounting them */}
      <AddWorkspaceModal
        key={`workspace-${dashboardState.dialogResetCounter}`}
        open={dashboardState.addingWorkspace}
        onClose={() =>
          setDashboardState(state => ({ ...state, addingWorkspace: false }))
        }
      />
      <AddQueueDialog
        key={`queue-${dashboardState.dialogResetCounter}`}
        open={dashboardState.addingQueue}
        onClose={() =>
          setDashboardState(state => ({ ...state, addingQueue: false }))
        }
        onSuccess={onQueueChange}
        currentQueueId={currentQueueId}
      />
      <ResearchCallSurvey
        surveyPlacement="documents"
        blockersSelector={dashboardResearchCallSurveyBlockersSelector}
      />
    </Stack>
  );
};

export default DocumentList;
