import { SchemaAction } from '@rossum/api-client/schemas';
import { DriveFileMoveOutlined } from '@rossum/ui/icons';
import { useIntl } from 'react-intl';
import { z } from 'zod';
import { QueueSelect } from '../../queues/select-queue/QueueSelect';
import { createForm } from './createForm';
import { ActionHeader, createHeader } from './createHeader';

const moveDocumentActionPayloadSchema = z.object({
  queue: z.string().nullable().default(null),
});

const MoveDocumentActionForm = createForm(({ value, onChange }) => {
  const intl = useIntl();
  return (
    <QueueSelect
      label={intl.formatMessage({
        id: 'features.queueSettings.rules.actions.moveDocument.form.queue',
      })}
      placeholder={intl.formatMessage({
        id: 'features.queueSettings.rules.actions.moveDocument.form.queuePlaceholder',
      })}
      value={value.queue}
      onChange={queue => onChange({ ...value, queue })}
    />
  );
}, moveDocumentActionPayloadSchema);

const MoveDocumentActionHeader = createHeader(
  ({ value }) => (
    <ActionHeader
      entries={[
        {
          key: 'queue',
          value: value.queue ?? '',
          sx: {
            fontFamily: 'monospace',
          },
        },
      ]}
    />
  ),
  moveDocumentActionPayloadSchema
);
export const moveDocumentAction = {
  icon: DriveFileMoveOutlined,
  resolveAction: (a: SchemaAction) => a.type === 'move_document',
  serialized: { type: 'move_document' },
  intlKey: 'moveDocument' as const,
  groupIntlKey: 'documentActions' as const,
  form: MoveDocumentActionForm,
  header: MoveDocumentActionHeader,
};
