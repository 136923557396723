import { addValidationSourceAction } from './AddValidationSourceAction';
import { automationBlockerAction } from './AutomationBlockerAction';
import { documentStateActions } from './DocumentStateAction';
import { emailAction } from './EmailAction';
import { labelAction } from './LabelAction';
import { messageActions } from './MessageAction';
import { moveDocumentAction } from './MoveDocumentAction';
import { visibilityActions } from './VisibilityAction';

export const knownActions = [
  ...messageActions,
  automationBlockerAction,
  emailAction,
  labelAction,
  ...visibilityActions,
  moveDocumentAction,
  ...documentStateActions,
  addValidationSourceAction,
] as const;

export const knownActionGroups = knownActions.map(
  action => action.groupIntlKey
);
